import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import { BasicPageProps } from "@middleware/types";
import attention from "@static/images/404/attention.jpg";
import style from "./NotFound.module.scss";

export const NotFound: FC<BasicPageProps> = ({ translation }) => {
  return (
    <div className="lk-wrap">
      <div className={style.main}>
        <div className={style.img}>
          <Image src={attention} alt="" />
        </div>
        <h1>{translation("notFound.title")}</h1>
        <p>{translation("notFound.subText")}</p>
        <p>{translation("notFound.moreText")}</p>
        <Link href="/">{translation("notFound.clickHere")}</Link>
      </div>
    </div>
  );
};
